
.app {
  font-size: 1em;
}

.homeContent {
  padding-top: 4.5em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

#main_hello{
  width: 80%;
  height: 100%;
  color: Black;
  font-family: "Reenie Beanie";
  letter-spacing: 5px;
  font-family: 1.5em;
  border-radius: 3em;
  box-shadow: -1em -1em black;
}

#visity_logo{
  box-shadow: 0em 0em .3em lightgray;
  border-radius: 10em;
  padding: 1em;
  height: 7em;                   
}

.webDevContent{
  padding-top: 5em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#main_pic{
  box-shadow: -.4em -.4em black;
  border: solid .2em white;
  border-radius: 3em;
  margin-top: 3em;
  margin-bottom: 1.5em;
  width: 300px;
}

#foodx_content_container{
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  justify-content: center;
}

#foox_text{
  color: black;
  letter-spacing: 3px;
  font-size: .9em;
  width: 80%;
  margin-top: 1em;
  margin: 3em
}

#foodx_home{
  width: 100%;
}

#foodx_plan{
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
}

#visity_home{
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
}

footer{
  background-color: black;
  color: white;
  letter-spacing: 3px; 
  font-size: 1.2em;
  margin-top: .91em;
  text-align: center;
}

@media only screen and (min-width: 768px) {

  footer{
    padding-left: 1em; 
    background-color: black;
    color: white;
    letter-spacing: 3px; 
    font-size: 1.5em;
    margin-top: .91em;
  }

  #visity_content_container{
    display: flex;
    flex-direction: row;
    width: 50%;
  }

  #visity_home{
    box-shadow: 0em 0em .3em lightgray;
    border: solid .2em white;
    border-radius: 2em;
    margin-top: 2em;
    margin-bottom: 2em;
    height: 20em;
    width: 50%;
  }

  #foodx_plan{
    box-shadow: 0em 0em .3em lightgray;
    border: solid .2em white;
    border-radius: 2em;
    padding: 1em;
    margin-top: 2em;
    margin-bottom: 2em;
    height: 20em;
    width: 50%;
  }

  #foodx_home{
    box-shadow: 0em 0em .3em lightgray;
    border: solid .2em white;
    border-radius: 2em;
    padding: 1em;
    height: 15em;
    width: 50%;
  }

  .app {
    min-height: 100vh;
  }

  #foox_text{
    color: black;
    letter-spacing: 3px;
    font-size: .9em;
    width: 30%;
    margin-top: 2.5em;
    margin-right: 1em; 
  }

  #foodx_content_container{
    display: flex;
    flex-direction: row;
    margin-top: 3em;
    justify-content: center;
  }
  
  .homeContent {
    padding-top: 4.5em;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  #main_hello{
    width: 40%;
    height: 100%;
    color: Black;
    font-family: "Reenie Beanie";
    letter-spacing: 5px;
    font-family: 1.5em;
    border-radius: 3em;
    box-shadow: -1em -1em black;
  }

  #main_pic{
    margin-top: -.5em;
    box-shadow: -.4em -.4em black;
    border: solid .2em white;
    border-radius: 3em;
    margin-bottom: 1.5em;
    width: 100%;
  }

  #visity_logo{
    box-shadow: 0em 0em .3em lightgray;
    border-radius: 10em;
    padding: 1em;
    height: 7em;                   
  }

  .webDevContent{
    padding-top: 5em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .navBarButtons {
    letter-spacing: 6px;
    font-size: larger;
    color: white;
  }
  
  
  .App-logo {
    height: 25em;
  }
  
  .App-link {
    color: #61dafb;
  }
}
